import { useState } from 'react';
import { FetchPolicy } from 'utils/enum/Core';
import StringUtils from 'lib/StringUtils';
import PDFUtils from 'utils/PDFUtils';
import ImagesUtils from 'utils/ImagesUtils';
import GeneralUtils from 'utils/GeneralUtils';
import { useApolloClient } from '@apollo/client';
import DocumentsQuery from 'services/graphQL/query/DocumentsQuery';

export default function usePreview() {
    const client = useApolloClient();
    const [state, setState] = useState({
        previewTitle: '',
        previewExt: null,
        previewURL: null,
        isProcessingFile: false,
    });

    const onPreviewDocument = async (record, isDownload = false) => {
        const {
            documentName,
            documentUrl,
        } = record;

        setState((prevState) => ({
            ...prevState,
            isProcessingFile: true,
        }));

        const path = documentUrl;
        const ext = path.substring(path.lastIndexOf('.') + 1)?.toLowerCase();

        let localUrl = null;
        if (record.isSecureUrl) {
            const isSecured = StringUtils.isSecureURL(documentUrl);
            localUrl = isSecured ? documentUrl : documentUrl.replace('http://', 'https://');
        } else {
            const { data } = await client.query({
                query: DocumentsQuery.GET_DOCUMENT_SECURE_URL,
                variables: {
                    path,
                },
                fetchPolicy: FetchPolicy.NO_CACHE,
            });

            const [name] = Object.keys(data || {});
            if (!name) return;

            const secureURL = data[name];
            if (!secureURL) {
                setState((prevState) => ({
                    ...prevState,
                    isProcessingFile: false,
                }));

                return;
            }

            if (ext === 'pdf') {
                localUrl = await PDFUtils.convertPDFToBlobURL(secureURL);
            } else {
                localUrl = await ImagesUtils.convertImageToBlobURL(secureURL, 'image/jpeg');
            }
        }

        if (isDownload) {
            GeneralUtils.downloadFile(localUrl, documentName);
            setState((prevState) => ({
                ...prevState,
                isProcessingFile: false,
            }));

            return;
        }

        setState((prevState) => ({
            ...prevState,
            previewTitle: `| ${documentName}`,
            previewExt: ext,
            previewURL: localUrl,
            isProcessingFile: false,
        }));
    };

    return {
        state,
        onPreviewDocument,
    };
}
