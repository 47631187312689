import Dropzone from 'react-dropzone';
import InboxOutlinedIcon from '@material-ui/icons/InboxOutlined';
import React, { useEffect, useState } from 'react';
import {
    Button, makeStyles, TextField, Tooltip,
    CircularProgress,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import SaveOutlinedIcon from '@material-ui/icons/SaveOutlined';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import CloudDownloadOutlinedIcon from '@material-ui/icons/CloudDownloadOutlined';
import clsx from 'clsx';
import DeleteOutlineOutlinedIcon from '@material-ui/icons/DeleteOutlineOutlined';
import ButtonStyles from 'styles/theme/Button';
import StringUtils from 'lib/StringUtils';
import DateUtils from 'lib/DateUtils';
import ModalUtils from 'utils/ModalUtils';
import FileManagerStyles from 'styles/theme/Files/FileManager';
import Loading from 'components/widgets/Loading';
import VirtualTable from 'components/widgets/VirtualTable';
import InputSearch from 'components/widgets/InputSearch';
import useFileManagerFunctions from 'components/hook/core/useFileManagerFunctions';
import ConfirmDialog from 'components/widgets/modal/ConfirmDialog';
import DocumentTypeInput from 'components/widgets/filemanager/DocumentTypeInput';

const buttonStyles = makeStyles((theme) => ButtonStyles.getStyle(theme));
const useStyles = makeStyles((theme) => FileManagerStyles.getStyle(theme));

const getStartIcon = (isEditMode, isSelected, renamingDocument) => {
    if (isEditMode && isSelected && renamingDocument) {
        return (
            <CircularProgress
                variant="indeterminate"
                value={100}
                className={clsx('back-circle')}
                size={16}
                thickness={4}
            />
        );
    }

    if (isEditMode && isSelected) {
        return <SaveOutlinedIcon />;
    }

    return <EditOutlinedIcon />;
};

const FileManager = ({ documentProp, onPreviewDocument = () => {} }) => {
    const [state, setState] = useState({
        documents: [],
        search: '',
        previewTitle: '',
        previewExt: null,
        previewURL: null,
        modifiedName: null,
        selectedDocumentId: null,
        selectedDocumentEditTypeId: null,
        isUploadingDocuments: false,
        isDeletePromptVisible: false,
        isProcessingFile: false,
        selectedDocumentType: null,
        otherText: '',
    });

    const classes = { ...useStyles(), ...buttonStyles() };

    const {
        documentsData,
        documentRename,
        deleteDocument,
        uploadDocuments,
        loadingDocuments,
        errorLoadingDocuments,
        renamingDocument,
        deletingDocument,
        documentTypesData,
        updateDocumentType,
        updatingDocumentType,
        isOther,
    } = useFileManagerFunctions(state, setState, documentProp);

    const onDeleteAction = () => {
        if (!documentProp.canDelete) return;

        deleteDocument({
            variables: {
                documentId: state.selectedDocumentId,
            },
        });
    };

    const toggleDeletePrompt = (record) => {
        if (!documentProp.canDelete) return;

        setState((prevState) => ({
            ...prevState,
            selectedDocumentId: record?.documentId,
            isDeletePromptVisible: !state.isDeletePromptVisible,
        }));
    };

    const sortDocumentsByName = (documents) => documents.map(((doc) => ({
        ...doc,
        documentName: StringUtils.getFileNameFromPath(doc.documentUrl),
    }))).sort((a, b) => a.documentName.localeCompare(b.documentName));

    useEffect(() => {
        if (errorLoadingDocuments) {
            ModalUtils.errorMessage(errorLoadingDocuments?.graphQLErrors);
            return;
        }

        if (!loadingDocuments) {
            const [name] = Object.keys(documentsData || {});
            if (!name) return;

            let documents = null;
            documents = documentsData[name];

            setState((prevState) => ({
                ...prevState,
                documents: sortDocumentsByName(documents || []),
            }));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loadingDocuments, errorLoadingDocuments]);

    const onSearch = (text) => {
        setState((prevState) => ({
            ...prevState,
            search: text,
        }));
    };

    const onChangeName = (value) => {
        if (['.', '/', '\\'].some((char) => value.includes(char))) return;

        setState((prevState) => ({
            ...prevState,
            modifiedName: value,
        }));
    };

    const editDocumentName = (record) => {
        const doc = (state.documents).find((el) => el.documentId === record?.documentId);

        setState((prevState) => ({
            ...prevState,
            selectedDocumentId: record?.documentId,
            modifiedName: doc?.documentName.split('.')[0],
        }));
    };

    const editDocumentType = (record) => {
        // Compare with the existing document types, if it is not in the list or 'All' then it is Other

        setState((prevState) => ({
            ...prevState,
            selectedDocumentEditTypeId: record?.documentId,
            selectedDocumentType: isOther(record) ? 'Other:' : record?.documentType,
            otherText: isOther(record) ? record?.documentType : '',
        }));
    };

    const updateDocumentDocumentType = async (value) => {
        if (state.selectedDocumentType === null) return;

        updateDocumentType({
            variables: {
                documentId: state.selectedDocumentEditTypeId,
                documentType: value,
            },
        });

        setState((prevState) => ({
            ...prevState,
            selectedDocumentEditTypeId: null,
            otherText: '',
        }));
    };

    const saveDocumentName = () => {
        if (
            (state.documents)
                .some((el) => el?.documentName.split('.')[0]?.toLowerCase() === state.modifiedName?.trim().toLowerCase())
        ) {
            setState((prevState) => ({
                ...prevState,
                selectedDocumentId: null,
                modifiedName: null,
            }));

            return;
        }

        documentRename({
            variables: {
                documentId: state.selectedDocumentId,
                name: state.modifiedName,
            },
        });
    };
    const getColumns = () => [
        {
            headerClassName: classes.tableHeader,
            label: 'Name',
            dataKey: 'name',
            width: 400,
            cellRenderer: (cell) => {
                const { rowData: record } = cell;

                const isEditMode = state.selectedDocumentId && !state.isDeletePromptVisible;
                const isSelected = record.documentId === state.selectedDocumentId;
                const name = record.documentName?.split('.')[0];
                const startIcon = getStartIcon(isEditMode, isSelected, renamingDocument);
                return (
                    <div className={classes.nameWrapper}>
                        {(!isEditMode || (isEditMode && !isSelected)) && (
                            <div
                                className={classes.text}
                                onClick={() => onPreviewDocument(record)}
                            >
                                {name}
                            </div>
                        )}
                        {isEditMode && isSelected && (
                            <TextField
                                className={classes.textFieldSmall}
                                defaultValue={state.modifiedName}
                                onChange={({ target: { value } }) => onChangeName(value)}
                                inputProps={{ maxLength: 100 }}
                                variant="outlined"
                                size="small"
                            />
                        )}
                        <Tooltip title="Rename File">
                            <div>
                                <Button
                                    disabled={renamingDocument || state.isProcessingFile}
                                    className={classes.containedInfo}
                                    size="small"
                                    startIcon={startIcon}
                                    onClick={() => (isEditMode && isSelected ? saveDocumentName() : editDocumentName(record))}
                                />
                            </div>
                        </Tooltip>
                    </div>
                );
            },
        },
        {
            headerClassName: classes.tableHeader,
            label: 'Document Type',
            dataKey: 'documentType',
            width: 280,
            cellRenderer: (cell) => {
                const { rowData: record } = cell;

                const isEditMode = state.selectedDocumentEditTypeId && !state.isDeletePromptVisible;
                const isSelected = record.documentId === state.selectedDocumentEditTypeId;
                const { documentType } = record;
                const startIcon = getStartIcon(isEditMode, isSelected, renamingDocument);

                return (
                    <DocumentTypeInput
                        documentTypesData={documentTypesData}
                        updateDocumentDocumentType={updateDocumentDocumentType}
                        documentType={isOther(record) ? 'Other:' : documentType}
                        isEditMode={isEditMode}
                        record={record}
                        isSelected={isSelected}
                        updatingDocumentType={updatingDocumentType}
                        editDocumentType={editDocumentType}
                        startIcon={startIcon}
                        onPreviewDocument={onPreviewDocument}
                        classes={classes}
                        otherText={isOther(record) ? documentType : ''}
                    />
                );
            },
        },
        {
            headerClassName: classes.tableHeader,
            label: 'Size',
            dataKey: 'size',
            width: 120,
            cellRenderer: (cell) => {
                const { rowData: record } = cell;

                return (
                    <span className={classes.text}>{record.documentSize}</span>
                );
            },
        },
        {
            headerClassName: classes.tableHeader,
            label: 'Extension',
            dataKey: 'type',
            width: 120,
            cellRenderer: (cell) => {
                const { rowData: record } = cell;

                const ext = record.documentName?.split('.')[1];
                return (
                    <span className={classes.text}>{ext?.toUpperCase()}</span>
                );
            },
        },
        {
            headerClassName: classes.tableHeader,
            label: 'Created On',
            dataKey: 'createdOn',
            width: 180,
            cellRenderer: (cell) => {
                const { rowData: record } = cell;

                return (
                    <span className={classes.text}>{DateUtils.getFormattedDateInUserTimezone(record.createdOn)}</span>
                );
            },
        },
        {
            headerClassName: classes.tableHeader,
            label: 'Actions',
            dataKey: 'actions',
            width: 150,
            cellRenderer: (cell) => {
                const { rowData: record } = cell;
                if (!documentProp.canWrite) return null;

                return (
                    <>
                        <Button
                            disabled={state.isProcessingFile}
                            className={classes.download}
                            size="small"
                            startIcon={<CloudDownloadOutlinedIcon />}
                            onClick={() => onPreviewDocument(record, true)}
                        />
                        {documentProp.canDelete && (
                            <Button
                                disabled={state.isProcessingFile}
                                className={clsx(classes.containedError, classes.deleteButton)}
                                size="small"
                                startIcon={<DeleteOutlineOutlinedIcon />}
                                onClick={() => toggleDeletePrompt(record)}
                            />
                        )}
                    </>
                );
            },
        },
    ];

    const docs = state.documents
        .filter((el) => (
            StringUtils.isEmpty(state.search)
            || (
                !StringUtils.isEmpty(state.search)
                && el.documentName?.toLowerCase().includes(state.search?.toLowerCase())
            )
        ));

    return (
        <>
            <div>
                <Dropzone
                    disabled={state.isUploadingDocuments || renamingDocument || state.isProcessingFile}
                    multiple
                    accept={documentProp.allowedFiles}
                    onDrop={(acceptedFiles) => uploadDocuments(acceptedFiles)}
                    className={classes.dragzone}
                >
                    <p className={classes.uploadText}>
                        Click or drag document to this area to upload (PDF and Images)
                    </p>
                    <InboxOutlinedIcon />
                </Dropzone>
            </div>
            <div>
                <InputSearch
                    executeWhenClearButton={() => onSearch('')}
                    onSearch={onSearch}
                />
            </div>
            <div>
                <div className={classes.tableContainer}>
                    <VirtualTable
                        loading={loadingDocuments}
                        rowHeight={45}
                        totalRecords={docs.length}
                        data={docs}
                        columns={getColumns()}
                        width={getColumns().reduce((a, b) => a + b.width, 0)}
                    />
                </div>
            </div>
            {state.isUploadingDocuments && (
                <Loading className={classes.loader} />
            )}

            <ConfirmDialog
                title="Attention!"
                description="Do you want to remove this document?"
                open={state.isDeletePromptVisible}
                variant="outlined"
                titlePrimary="Yes"
                titleSecondary="Cancel"
                onClose={() => toggleDeletePrompt()}
                onClickSecondary={() => toggleDeletePrompt()}
                onClickPrimary={onDeleteAction}
                disablePrimaryButton={deletingDocument}
                disableSecondaryButton={deletingDocument}
            />
        </>
    );
};

FileManager.propTypes = {
    documentProp: PropTypes.shape({
        allowedFiles: PropTypes.string,
        pullDocumentsQuery: PropTypes.any.isRequired,
        getDocumentUploadSignedURL: PropTypes.any.isRequired,
        getDocumentSecureURL: PropTypes.any.isRequired,
        createDocumentsMutation: PropTypes.any.isRequired,
        editDocumentNameMutation: PropTypes.any.isRequired,
        deleteMutation: PropTypes.any.isRequired,
        canWrite: PropTypes.bool.isRequired,
        canDelete: PropTypes.bool.isRequired,
        showPreview: PropTypes.bool,
        maxFileSize: PropTypes.number,
        referenceId: PropTypes.number,
        referenceType: PropTypes.string.isRequired,
        referenceName: PropTypes.string.isRequired,
        documentTypeMutation: PropTypes.any.isRequired,
    }).isRequired,
    onPreviewDocument: PropTypes.func.isRequired,
};
export default FileManager;
