export default class FilePreviewStyles {
    static getStyle(theme = {}) {
        return ({
            preview: {
                border: `1px solid ${theme.palette.border.ghost}`,
                display: 'flex',
                flexDirection: 'column',
                overflow: 'hidden',
                [theme.breakpoints.down('sm')]: {
                    height: '600px',
                    marginTop: '48px',
                },
                '& > div:nth-child(1)': {
                    display: 'flex',
                    justifyContent: 'flex-start',
                    alignContent: 'center',
                    padding: '10px',
                    borderBottom: `1px solid ${theme.palette.border.ghost}`,
                    color: theme.palette.text.trout,
                    fontSize: '14px',
                    flexShrink: 0,
                },
                '& > div:nth-child(2)': {
                    padding: '5px',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    flexGrow: 1,
                    overflowY: 'auto',
                    position: 'relative',
                    '& > div:nth-child(1)': {
                        fontSize: '13px',
                    },
                },
            },
            iframe: {
                height: '100%',
                width: '100%',
            },
            innerImage: {
                width: '100%',
            },
            loader: {
                position: 'absolute',
                left: '0',
                top: '0',
                zIndex: '999',
                backgroundColor: theme.palette.background.white,
                width: '100%',
                height: '98%',
                opacity: '0.5',
            },
        });
    }
}
