export default class FileManagerContainerStyles {
    static getStyle(theme = {}) {
        return ({
            container: {
                height: 'calc(100vh - 150px)',
                padding: '10px',
                [theme.breakpoints.down('sm')]: {
                    display: 'flex',
                    height: 'initial',
                    flexDirection: 'column',
                    marginBottom: '20px',
                },
            },
            splitter: {
                display: 'flex',
                overflow: 'hidden',
                height: '100%',
                width: '100%',
                '& > div.gutter': {
                    marginLeft: '5px',
                    marginRight: '5px',
                },
            },
            associatedSplitter: {
                height: '100%',
                [theme.breakpoints.down('sm')]: {
                    height: '800px',
                },
                flexDirection: 'column',
                fontSize: 'initial !important',
                flexShrink: 'initial !important',
                marginBottom: 'initial !important',
                '& > div.gutter': {
                    marginLeft: 0,
                    marginRight: 0,
                },
            },
            files: {
                border: `1px solid ${theme.palette.border.ghost}`,
                display: 'flex',
                flexDirection: 'column',
                overflow: 'hidden',
                position: 'relative',
                [theme.breakpoints.down('sm')]: {
                    height: '600px',
                },
                '& > div:nth-child(1)': {
                    marginBottom: '2px',
                    fontSize: '13px',
                    flexShrink: 0,
                },
                '& > div:nth-child(2)': {
                    display: 'flex',
                    justifyContent: 'flex-start',
                    flexShrink: 0,
                    '& > div': {
                        width: '250px',
                        '& input': {
                            borderRadius: 'initial',
                        },
                    },
                },
                '& > div:nth-child(3)': {
                    flexGrow: 1,
                    overflowX: 'auto',
                    overflowY: 'hidden',
                },
            },
            navbar: {
                '& > a': {
                    color: theme.palette.text.link,
                    'text-decoration': 'none',
                    'background-color': 'transparent',
                    'line-height': '1',
                    fontSize: '13px',
                },
            },
            borderContainer: {
                '& + div': {
                    boxShadow: '0px 1px 4px rgba(0, 0, 0, 0.1)',
                    marginLeft: '0.5px',
                },
            },
            tab: {
                width: '100%',
                height: '90%',
            },
        });
    }
}
